*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

.-top-2 {
  top: -.5rem !important;
}

.left-0 {
  left: 0 !important;
}

.left-1 {
  left: .25rem !important;
}

.left-1\.5 {
  left: .375rem !important;
}

.right-1 {
  right: .25rem !important;
}

.right-1\.5 {
  right: .375rem !important;
}

.top-0 {
  top: 0 !important;
}

.top-10 {
  top: 2.5rem !important;
}

.-z-50 {
  z-index: -50 !important;
}

.z-30 {
  z-index: 30 !important;
}

.z-50 {
  z-index: 50 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-1\.5 {
  margin: .375rem !important;
}

.-mx-2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.-mb-2 {
  margin-bottom: -.5rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-1\.5 {
  margin-bottom: .375rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: .75rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.ml-3 {
  margin-left: .75rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mr-1\.5 {
  margin-right: .375rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mr-3 {
  margin-right: .75rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.table {
  display: table !important;
}

.grid {
  display: grid !important;
}

.hidden {
  display: none !important;
}

.h-1 {
  height: .25rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-9 {
  height: 2.25rem !important;
}

.h-fit {
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-2\/3 {
  width: 66.6667% !important;
}

.w-56 {
  width: 14rem !important;
}

.w-fit {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.w-full {
  width: 100% !important;
}

.min-w-full {
  min-width: 100% !important;
}

.max-w-2xl {
  max-width: 42rem !important;
}

.max-w-fit {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

.max-w-screen-md {
  max-width: 768px !important;
}

.max-w-screen-sm {
  max-width: 640px !important;
}

.max-w-screen-xl {
  max-width: 1280px !important;
}

.grow {
  flex-grow: 1 !important;
}

.table-auto {
  table-layout: auto !important;
}

.border-collapse {
  border-collapse: collapse !important;
}

.scale-100 {
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.scale-90 {
  --tw-scale-x: .9 !important;
  --tw-scale-y: .9 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

@keyframes ping-sm {
  0% {
    opacity: .75;
    transform: scale(1);
  }

  50% {
    opacity: 0;
    transform: scaleY(1.5)scaleX(1.1);
  }

  100% {
    opacity: 0;
    transform: scaleY(1.5)scaleX(1.1);
  }
}

.animate-ping-sm {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite ping-sm !important;
}

@keyframes popup {
  0% {
    transform: scale(.2);
  }

  80% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.animate-popup {
  animation: .2s ease-in-out popup !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.list-disc {
  list-style-type: disc !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.flex-col {
  flex-direction: column !important;
}

.items-center {
  align-items: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: .75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.break-words {
  overflow-wrap: break-word !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-lg {
  border-radius: .5rem !important;
}

.rounded-md {
  border-radius: .375rem !important;
}

.border {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-b {
  border-bottom-width: 1px !important;
}

.border-r {
  border-right-width: 1px !important;
}

.border-t {
  border-top-width: 1px !important;
}

.border-t-0 {
  border-top-width: 0 !important;
}

.border-amber-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
}

.border-blue-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
}

.border-blue-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
}

.border-emerald-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}

.border-green-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
}

.border-green-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
}

.border-neutral-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.border-red-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.border-red-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
}

.border-rose-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
}

.border-yellow-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
}

.border-opacity-25 {
  --tw-border-opacity: .25 !important;
}

.bg-amber-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity)) !important;
}

.bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}

.bg-blue-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity)) !important;
}

.bg-blue-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
}

.bg-blue-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
}

.bg-blue-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity)) !important;
}

.bg-emerald-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity)) !important;
}

.bg-green-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity)) !important;
}

.bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity)) !important;
}

.bg-green-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity)) !important;
}

.bg-indigo-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity)) !important;
}

.bg-neutral-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important;
}

.bg-neutral-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity)) !important;
}

.bg-neutral-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity)) !important;
}

.bg-neutral-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity)) !important;
}

.bg-neutral-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity)) !important;
}

.bg-neutral-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity)) !important;
}

.bg-purple-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity)) !important;
}

.bg-red-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity)) !important;
}

.bg-rose-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity)) !important;
}

.bg-sky-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity)) !important;
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity)) !important;
}

.bg-opacity-10 {
  --tw-bg-opacity: .1 !important;
}

.bg-opacity-30 {
  --tw-bg-opacity: .3 !important;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5 !important;
}

.bg-opacity-60 {
  --tw-bg-opacity: .6 !important;
}

.bg-opacity-70 {
  --tw-bg-opacity: .7 !important;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-1\.5 {
  padding-left: .375rem !important;
  padding-right: .375rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-0\.5 {
  padding-top: .125rem !important;
  padding-bottom: .125rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-2\.5 {
  padding-top: .625rem !important;
  padding-bottom: .625rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-24 {
  padding-bottom: 6rem !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-0\.5 {
  padding-top: .125rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-2\.5 {
  padding-top: .625rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-sm {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.text-xs {
  font-size: .75rem !important;
  line-height: 1rem !important;
}

.text-xxs {
  font-size: .625rem !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.tracking-wide {
  letter-spacing: .025em !important;
}

.text-blue-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(37 99 235 / var(--tw-text-opacity)) !important;
}

.text-blue-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(29 78 216 / var(--tw-text-opacity)) !important;
}

.text-blue-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(30 58 138 / var(--tw-text-opacity)) !important;
}

.text-green-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(22 101 52 / var(--tw-text-opacity)) !important;
}

.text-neutral-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(115 115 115 / var(--tw-text-opacity)) !important;
}

.text-neutral-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(82 82 82 / var(--tw-text-opacity)) !important;
}

.text-neutral-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(64 64 64 / var(--tw-text-opacity)) !important;
}

.text-neutral-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(38 38 38 / var(--tw-text-opacity)) !important;
}

.text-neutral-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(23 23 23 / var(--tw-text-opacity)) !important;
}

.text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.underline {
  text-decoration-line: underline !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040 !important;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d !important;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a !important;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.outline-none {
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
}

.blur {
  --tw-blur: blur(8px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.transition-all {
  transition-property: all !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.transition-shadow {
  transition-property: box-shadow !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

* {
  box-sizing: border-box;
  font-family: ui-sans-serif, system-ui, sans-serif;
}

.even\:bg-neutral-50:nth-child(2n) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity)) !important;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.hover\:bg-amber-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-neutral-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-neutral-200:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-neutral-50:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity)) !important;
}

.hover\:text-neutral-900:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(23 23 23 / var(--tw-text-opacity)) !important;
}

.hover\:underline:hover {
  text-decoration-line: underline !important;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

@keyframes wiggle {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0);
  }
}

.group:hover .group-hover\:animate-wiggle {
  animation: .6s wiggle !important;
}

@media (width >= 768px) {
  .md\:flex {
    display: flex !important;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:flex-row {
    flex-direction: row !important;
  }

  .md\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

@media (width >= 1024px) {
  .lg\:w-1\/2 {
    width: 50% !important;
  }

  .lg\:w-auto {
    width: auto !important;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:flex-row {
    flex-direction: row !important;
  }

  .lg\:items-start {
    align-items: flex-start !important;
  }

  .lg\:justify-start {
    justify-content: flex-start !important;
  }

  .lg\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lg\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lg\:text-left {
    text-align: left !important;
  }
}

/*# sourceMappingURL=index.62304db0.css.map */
